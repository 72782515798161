import { createGlobalState } from '@vueuse/core'
import {
    useBankStore,
    useAlertStore,
    useUserStore,
    useModalStore,
} from '~/stores'
import useCustomFetch from '~/composables/useCustomFetch'
import { storeToRefs } from 'pinia'

export const useSharedKyc = createGlobalState(() => {
    const alertStore = useAlertStore()
    const fetcher = useCustomFetch()
    const bankStoreInstance = useBankStore()
    const { getUserBankAccounts } = bankStoreInstance
    const bankActive = ref(null)
    const images = ref([null, null])
    const useUserStoreInstance = useUserStore()
    const { user } = storeToRefs(useUserStoreInstance)
    const IS_KYC = useRuntimeConfig().public.IS_KYC

    const useModalStoreInstance = useModalStore()

    const { showKycRequired, showKycVerificationPending } = storeToRefs(
        useModalStoreInstance
    )

    const isLoadingLeft = ref(false)
    const isLoadingRight = ref(false)

    const isLoadingComponent = ref(false)
    const isShowModal = ref(false)
    const INIT_STEP = {
        PERSONAL_INFO: 1,
        UPLOAD_DOCUMENT: 2,
        COMPLETE: 3,
    }

    const INIT_TYPE = {
        ID: 'id',
        PASSPORT: 'passport',
    }

    const imageUpload = ref([null, null])

    const stepActive = ref(INIT_STEP.PERSONAL_INFO)

    const step1Form = ref({
        documentType: INIT_TYPE.ID,
        identificationNumber: null,
        fullName: null,
        dateOfBirth: {
            days: null,
            months: null,
            years: null,
        },
        gender: '',
    })

    const INIT_STEP_NAME = computed(() => {
        const object = {
            [INIT_STEP.PERSONAL_INFO]: 'kyc.personal_information',
            [INIT_STEP.UPLOAD_DOCUMENT]:
                step1Form.value.documentType === INIT_TYPE.ID
                    ? 'kyc.upload_your_id_card'
                    : 'kyc.upload_your_passport_id',
            [INIT_STEP.COMPLETE]: 'kyc.complete',
        }
        return object[stepActive.value]
    })

    const submitForm = async () => {
        try {
            const { data } = await fetcher.post('/user/kyc', {
                identity_type: step1Form.value.documentType,
                identity_id: step1Form.value.identificationNumber,
                identity_fullname: step1Form.value.fullName,
                identity_birth_day: Number(step1Form.value.dateOfBirth.days),
                identity_birth_month: Number(
                    step1Form.value.dateOfBirth.months
                ),
                identity_birth_year: Number(step1Form.value.dateOfBirth.years),
                identity_gender: step1Form.value.gender,
                identity_images: imageUpload.value,
                identity_origin_images: imageUpload.value,
            })
            console.log(data, '============')
            if (data.value.status === 'OK') {
                stepActive.value = INIT_STEP.COMPLETE
            }
        } catch (error) {
            alertStore.showMessageError(error)
        } finally {
            isLoadingComponent.value = false
        }
    }
    const submitVerify = async () => {
        isLoadingComponent.value = true
        const imagesFilter = images.value.filter((item) => !!item)

        const { data } = await fetcher.post('/user/upload/kyc', {
            images: imagesFilter,
        })
        imageUpload.value = data.value.data

        submitForm()
    }

    const getUserBank = async () => {
        try {
            const { data } = await getUserBankAccounts()
            const userBanks = JSON.parse(JSON.stringify(data.value[0]))
            bankActive.value = userBanks.bank_account_name
            step1Form.value.fullName = bankActive.value
        } catch (error) {
            console.error(error)
        }
    }

    onMounted(async () => {
        // await getUserBank()
    })
    const openModal = async () => {
        window.scrollTo(0, 1)
        await getUserBank()

        isShowModal.value = true
        document.body.style.overflow = 'hidden'
    }

    const resetValue = () => {
        images.value = []
        stepActive.value = 1
        step1Form.value = {
            documentType: INIT_TYPE.ID,
            identificationNumber: null,
            fullName: null,
            dateOfBirth: {
                days: null,
                months: null,
                years: null,
            },
            gender: '',
        }
        if (bankActive.value) {
            step1Form.value.fullName = bankActive.value
        }
    }

    const closeModal = () => {
        isShowModal.value = false
        document.body.style.overflow = 'auto'
        //reset
        resetValue()
    }

    const checkAccountVerifyKyc = async () => {
        if (IS_KYC === '0') {
            return false
        }
        // tobe improved
        await useUserStoreInstance.refresh()
        if (!user.value.kyc_status || user.value.kyc_status === 'REJECTED') {
            showKycRequired.value = true
        }
        if (user.value.kyc_status === 'NEW') {
            showKycVerificationPending.value = true
        }
        if (user.value.kyc_status === 'VERIFIED') {
            return false
        }
        return true
    }

    return {
        INIT_STEP,
        stepActive,
        INIT_STEP_NAME,
        step1Form,
        INIT_TYPE,
        bankActive,
        images,
        isLoadingComponent,
        submitVerify,
        closeModal,
        isShowModal,
        openModal,
        isLoadingLeft,
        isLoadingRight,
        checkAccountVerifyKyc,
    }
})
